import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import Login from './login';
import './App.css';
import { Config } from './config'
import { Editor } from '@tinymce/tinymce-react';

import NoteIcon from './icon/notepad.js';
import FolderIcon from './icon/folder.js';
import TrashIcon from './icon/trash.js';
import EditIcon from './icon/edit.js';
import SettingsIcon from './icon/settings.js';

const SAVE_STATUS_NONE = 0;
const SAVE_STATUS_DIRTY = 1;
const SAVE_STATUS_SAVING = 2;
const SAVE_STATUS_SAVED = 3;

function resolveItem(item) {
  while (item && !item.classList.contains('sidebaritem') && !item.classList.contains('treeEntry')) {
    item = item.parentElement;
  }
  return item;
}

function handleDragEnter(e) {
  var item = resolveItem(e.target);
  if (item.dataset.type === 'dir') {
    e.preventDefault();
    if (item.dataset.counter === undefined) {
      item.dataset.counter = 0;
    }
    item.dataset.counter++;
    item.classList.add('dragHighlight');
  }
}

function handleDragOver(e) {
  var item = resolveItem(e.target);
  if (item.dataset.type === 'dir') {
    e.preventDefault();
  }
}

function handleDragLeave(e) {
  var item = resolveItem(e.target);
  if (item.dataset.type === 'dir') {
    item.dataset.counter--;
    if (item.dataset.counter == 0) {
      item.classList.remove('dragHighlight');
    }
  }
}

function getErrStr(err) {
  switch (err) {
  case 'nopermission': return "This page couldn't be loaded";
  case 'servererror': return "Internal server error";
  default: return 'Unknown error';
  }
}

function SidebarItem({id, title, type, timestamp, handleClick, handleRenameItem, handleDeleteItem, currentSelection, handleDrop}) {
  var d = new Date(timestamp);

  function handleDragStart(e) {
    e.dataTransfer.setData('text', JSON.stringify({id: e.target.dataset.id, type: e.target.dataset.type, title: e.target.dataset.title}));

    var bottomBar = document.querySelector('.sidebarbottombar');
    var w = document.querySelector('.sidebar').clientWidth;
    bottomBar.style.width = `${w}px`;
    bottomBar.style.display = 'block';
  }

  function handleDragEnd(e) {
    var bottomBar = document.querySelector('.sidebarbottombar');
    bottomBar.style.display = 'none';
  }

  function handleMouseEnter(e) {
    var trash = resolveItem(e.target).querySelectorAll('.sidebaritemtrash');
    trash.forEach((ele) => {
      ele.style.display = 'flex';
    })
  }

  function handleMouseLeave(e) {
    var trash = resolveItem(e.target).querySelectorAll('.sidebaritemtrash');
    trash.forEach((ele) => {
      ele.style.display = 'none';
    });
  }

  var inner;

  if (type === 'file') {
    inner = (
      <>
        <div className='sidebaritemtrash' onClick={handleDeleteItem}><TrashIcon /></div>
        <div className='sidebaritemname'>
          {title}
        </div>
        <div className='sidebaritemdate'>
          {d.toLocaleDateString(navigator.language, {year: 'numeric', month: 'short', day: 'numeric'})} {d.toLocaleTimeString(navigator.language)}
        </div>
      </>
    );
  } else if (type === 'dir') {
    inner = (
      <>
        <div className='sidebaritemtrash' style={{right: '3em'}} onClick={handleRenameItem}><EditIcon /></div>
        <div className='sidebaritemtrash' onClick={handleDeleteItem}><TrashIcon /></div>
        <div className='sidebaritemname'>
          <FolderIcon /> {title}
        </div>
      </>
    );
  }

  return (
    <div className='sidebaritem' draggable='true' onClick={handleClick} onDragStart={handleDragStart} onDragEnd={handleDragEnd} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDrop={handleDrop} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} data-id={id} data-type={type} data-title={title} data-selected={type === 'file' && currentSelection == id}>
      {inner}
    </div>
  );
}

function Menu({items, x, y, closeMenu}) {
  if (items.length == 0) {
    return (<></>);
  }

  function MenuItem({text, action}) {
    function doAction(action) {
      action();
      closeMenu();
    }

    return <div className='menuItem' onClick={() => doAction(action)}>{text}</div>;
  }

  let menuItems = [];
  for (var i = 0; i < items.length; i++) {
    menuItems.push(<MenuItem key={`menu-item-${i}`} text={items[i].text} action={items[i].action}></MenuItem>);
  }

  return (
    <div className="menu" style={{left: `${x}px`, top: `${y}px`}}>
      {menuItems}
    </div>
  );
}

function Sidebar({folder, note, onLoadNote, barState, session, handleServerMessage, toggleSettingsMenu}) {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [trees, setTrees] = useState([]);
  const [root, setRoot] = useState(null);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setForceUpdate(forceUpdate + 1);
    }, 10000);

    return () => {
      clearInterval(interval);
    }
  }, [forceUpdate]);

  function setFolder(folder) {
    navigate(`/${folder}/${note === undefined ? '' : note}`);
  }

  function selectItem(event) {
    var item = resolveItem(event.target);
    if (!item) {
      return;
    }

    if (item.dataset.type === 'file') {
      onLoadNote(parseInt(item.dataset.id));
    } else if (item.dataset.type === 'dir') {
      setFolder(item.dataset.id);
    }
  }

  function browseTree(event) {
    setFolder(event.target.dataset.id);
  }

  function dropItem(event) {
    event.preventDefault();
    var item = resolveItem(event.target);
    if (!item) {
      return;
    }

    var dragData = JSON.parse(event.dataTransfer.getData('text'));
    item.classList.remove('dragHighlight');
    item.dataset.counter = 0;

    fetch(`${Config.serverAddr}/move`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        session: session,
        type: dragData.type,
        id: dragData.id,
        from: folder,
        to: item.dataset.id
      })
    })
    .then((response) => response.json())
    .then((data) => {
      handleServerMessage(data, () => {
        setForceUpdate(forceUpdate + 1);
      });
    })
    .catch((error) => console.error(error));
  }

  function resolveTrashItem(item) {
    while (item && !item.classList.contains('sidebartrashoverlay')) {
      item = item.parentElement;
    }

    return item;
  }

  function trashDragEnter(e) {
    e.preventDefault();

    var item = resolveTrashItem(e.target);
    if (item.dataset.counter === undefined) {
      item.dataset.counter = 0;
    }
    item.dataset.counter++;
    item.classList.add('sidebartrashoverlayhighlight');
  }

  function trashDragOver(e) {
    e.preventDefault();
  }

  function trashDragDrop(e) {
    e.preventDefault();

    var item = resolveTrashItem(e.target);
    item.classList.remove('sidebartrashoverlayhighlight');
    item.dataset.counter = 0;

    var dragData = JSON.parse(e.dataTransfer.getData('text'));
    deleteItemInternal(dragData.type, dragData.id, dragData.title);
  }

  function trashDragLeave(e) {
    e.preventDefault();

    var item = resolveTrashItem(e.target);
    item.dataset.counter--;
    if (item.dataset.counter == 0) {
      item.classList.remove('sidebartrashoverlayhighlight');
    }
  }

  function TreeEntry({id, title, arrow, handleClick, handleDrop}) {
    return <>{arrow && <> &#x279C; </>}<span className='treeEntry' data-id={id} data-type='dir' onClick={handleClick} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDrop={handleDrop}>{title}</span></>
  }

  function newNote(event) {
    var s = prompt('New note name:');

    if (s) {
      fetch(`${Config.serverAddr}/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          type: 'file',
          title: s,
          parent: folder,
          session: session
        })
      })
      .then((response) => response.json())
      .then((data) => {
        handleServerMessage(data, () => {
          onLoadNote(data.id);
        });
      })
      .catch((error) => console.error(error));
    }
  }

  function newFolder(event) {
    var s = prompt('New folder name:');

    if (s) {
      fetch(`${Config.serverAddr}/new`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          type: 'dir',
          title: s,
          parent: folder,
          session: session
        })
      })
      .then((response) => response.json())
      .then((data) => {
        handleServerMessage(data, () => {
          setForceUpdate(forceUpdate + 1);
        });
      })
      .catch((error) => console.error(error));
    }
  }

  function deleteItemInternal(type, id, title) {
    if (window.confirm(`Are you sure you want to delete "${title}"?`)) {
      fetch(`${Config.serverAddr}/delete`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          session: session,
          type: type,
          id: id,
          parent: folder
        })
      })
      .then((response) => response.json())
      .then((data) => {
        handleServerMessage(data, () => {
          setForceUpdate(forceUpdate + 1);
          if (type == 'file' && id == note) {
            onLoadNote(null);
          }
        });
      })
      .catch((error) => console.error(error));
    }
  }

  function deleteItem(e) {
    e.stopPropagation();

    var item = resolveItem(e.target);

    deleteItemInternal(item.dataset.type, item.dataset.id, item.dataset.title);
  }

  function renameItem(e) {
    e.stopPropagation();

    var item = resolveItem(e.target);

    if (item.dataset.type == 'dir') {
      var newName = prompt('Enter new name', item.dataset.title);
      if (newName) {
        fetch(`${Config.serverAddr}/rename`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            session: session,
            type: item.dataset.type,
            id: item.dataset.id,
            title: newName
          })
        })
        .then((response) => response.json())
        .then((data) => {
          handleServerMessage(data, () => {
            setForceUpdate(forceUpdate + 1);
          });
        })
        .catch((error) => console.error(error));
      }
    }
  }

  useEffect(() => {
    if (!folder) {
      setTrees([]);
      setItems([]);
      return;
    }

    fetch(`${Config.serverAddr}/list`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        folder: folder,
        session: session
      })
    })
    .then((response) => response.json())
    .then((data) => {
      handleServerMessage(data, () => {
        const items = data.items.map((item) =>
          <SidebarItem key={item.type + item.id} id={item.id} title={item.title} type={item.type} timestamp={item.modified} handleClick={selectItem} handleRenameItem={renameItem} handleDeleteItem={deleteItem} currentSelection={note} handleDrop={dropItem}></SidebarItem>
        );

        function generateTreeId(treeIndex, folderId) {
          return `t.${treeIndex}.${folderId}`;
        }

        var trees = data.tree.map((tree, i) => {
          var tree = data.tree[i].map((folder) =>
            <TreeEntry key={generateTreeId(i, folder.id)} id={folder.id} title={folder.title} arrow='true' handleClick={browseTree} handleDrop={dropItem}></TreeEntry>
          );

          if (tree.length > 0 && root) {
            tree.unshift(<TreeEntry key={generateTreeId(i, root)} onClick={browseTree} id={root} title='Home' handleClick={browseTree} handleDrop={dropItem}></TreeEntry>);
          }

          return <div className='dirTree' key={`dt.${i}`}>{tree}</div>
        })

        setTrees(trees);
        setItems(items);
        setServerError(null);
      }, (err) => {
        setServerError(err);
      });
    })
    .catch((error) => console.error(error));
  }, [note, folder, barState, forceUpdate, root]);

  useEffect(() => {
    fetch(`${Config.serverAddr}/root`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        session: session
      })
    })
    .then((response) => response.json())
    .then((data) => {
      handleServerMessage(data, () => {
        setRoot(data.root);

        if (!folder) {
          navigate(`/${data.root}`);
        }
      });
    })
    .catch((error) => console.error(error));
  }, [])

  var msg;
  if (!items.length) {
    msg = (
      <>
        <div className='sidebarmessage'>{serverError ? getErrStr(serverError) : 'This folder is empty'}</div>
        {serverError && <div className='sidebarmessage treeEntry' onClick={() => {setFolder(root)}}>Back to home</div>}
      </>
    );
  } else {
    msg = <></>
  }

  return (
    <div className='sidebar'>
      <div className='sidebarbottombar'>
        <div className='sidebartrashoverlay' onDragEnter={trashDragEnter} onDragOver={trashDragOver} onDragLeave={trashDragLeave} onDrop={trashDragDrop}><TrashIcon /></div>
      </div>
      <div className='sidebartoolbar'>
        <button onClick={newNote}><NoteIcon /> New Note</button>
        <button onClick={newFolder}><FolderIcon /> New Folder</button>
        <button onClick={toggleSettingsMenu}><SettingsIcon /></button>
      </div>
      {trees}
      {items}
      {msg}
    </div>
  );
}

function NoteTitle({note, title, onUpdateTitle}) {
  return (
    <div className="notetitle" contentEditable='true' onBlur={onUpdateTitle} dangerouslySetInnerHTML={{__html: title}}></div>
  );
}

function NoteEditor({note, saveStatus, session, onLoadNote, onUpdateNote, onUpdateTitle, handleServerMessage, handleUpdateTitle, dirty, setDirty, modTime, setModTime, editorRef}) {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [bodyState, setBodyState] = useState(0);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [serverError, setServerError] = useState(null);

  useEffect(() => {
    let interval = null;

    if (dirty == 0) {
      console.log('set timeout');
      interval = setInterval(() => {
        fetch(`${Config.serverAddr}/modified`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            session: session,
            id: note
          })
        })
        .then((response) => response.json())
        .then((data) => {
          handleServerMessage(data, () => {
            console.log('comparing local time', modTime, 'to remote time', data.modified);
            if (data.modified > modTime) {
              console.log('forcing update');
              setForceUpdate(forceUpdate + 1);
            } else {
              console.log('local note is still up to date');
            }
          }, (err) => {
            setServerError(err);
          });
        })
        .catch((error) => console.error(error));
      }, 10000);
    }

    return () => {
      if (interval) {
        console.log('cleared timeout');
        clearInterval(interval);
      }
    }
  }, [forceUpdate, dirty, modTime]);

  useEffect(() => {
    if (!note) {
      return;
    }

    fetch(`${Config.serverAddr}/read`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        session: session,
        id: note
      })
    })
    .then((response) => response.json())
    .then((data) => {
      handleServerMessage(data, () => {
        setServerError(null);
        setTitle(data.title);
        setBody(data.body);
        setModTime(data.modified);

        // HACK: Force update
        setBodyState(bodyState + 1);
      }, (err) => {
        setServerError(err);
      });
    })
    .catch((error) => console.error(error));
  }, [note, forceUpdate]);

  var status = '';
  switch (saveStatus) {
  case SAVE_STATUS_DIRTY:
    status = 'Unsaved';
    break;
  case SAVE_STATUS_SAVING:
    status = 'Saving...';
    break;
  case SAVE_STATUS_SAVED:
    status = 'Saved';
    break;
  }

  if (note == null) {
    return;
  } else if (serverError) {
    return (
      <>
        <div className='sidebarmessage'>{getErrStr(serverError)}</div>
        <div className='sidebarmessage treeEntry' onClick={() => {onLoadNote(null)}}>Close</div>
      </>
    );
  } else {
    return (
      <div className="notearea">
        <div className="noteheader">
          <NoteTitle note={note} title={title} onUpdateTitle={(e) => { if (e.target.innerHTML != title) { handleUpdateTitle(note, e.target.innerHTML); setTitle(e.target.innerHTML); } }}></NoteTitle>
          <div className="notedetails">{status}</div>
          <div className="noteclose" onClick={() => onLoadNote(null)}>&#10005;</div>
        </div>
        <Editor
          init={{
            height: '100%',
            menubar: false,
            skin: 'oxide-dark',
            content_css: 'dark',
            resize: false,
            statusbar: false,
            plugins: 'link lists wordcount table searchreplace hr emoticons charmap',
            toolbar: 'undo redo | styles fontfamily fontsize | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | subscript superscript | bullist numlist outdent indent hr | forecolor backcolor link | table | emoticons charmap | searchreplace wordcount'
          }}
          apiKey='6e9fil5vxadl9grkgqnu38tezd0pfmhamdb9tp4fzywyg8re'
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={body}
          onDirty={() => {
            setDirty(dirty + 1)
          }}
        />
      </div>
    );
  }
}

function confirmExit() {
  return "You have unsaved changes, are you sure you wish to exit?";
}

function Main() {
  const [cookies, setCookie, removeCookie] = useCookies(['session']);
  const navigate = useNavigate();
  let { folder, note } = useParams();
  const [barState, setBarState] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [dirty, setDirty] = useState(0);
  const editorRef = useRef(null);
  const [saveStatus, setSaveStatus] = useState(SAVE_STATUS_NONE);
  const [menu, setMenu] = useState([]);
  const [menuPos, setMenuPos] = useState({x: 0, y: 0});
  const [modTime, setModTime] = useState(0);

  const dirtyRef = useRef();
  dirtyRef.current = dirty;

  const noteRef = useRef();
  noteRef.current = note;

  // Detect "dirty" signals from editor
  useEffect(() => {
    if (dirty) {
      setSaveStatus(SAVE_STATUS_DIRTY);

      // Create timer
      const setData = setTimeout(() => {
        saveNow(note, editorRef.current.getContent());
        setDirty(0);
      }, 1000);

      // Prevent auto-closing tab
      window.onbeforeunload = confirmExit;

      // Set editor as undirty
      editorRef.current.save();

      return () => {
        clearTimeout(setData);
        window.onbeforeunload = null;
      }
    }
  }, [dirty, note]);

  // Effect for screen resizing
  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(window.innerWidth);
    }

    window.addEventListener('resize', updateDimension);

    return(() => {
      window.removeEventListener('resize', updateDimension);
    });
  }, [screenWidth]);

  if (!cookies.session) {
    return <Login setCookie={setCookie} />;
  }

  function handleServerMessage(data, success, failure = null) {
    if (data.success) {
      if (success) {
        success(data);
      }
    } else if (data.error == 'badauth') {
      removeCookie('session');
    } else if (failure) {
      failure(data.error);
    } else {
      console.error(data.error);
    }
  }

  function Logout() {
    navigate('/');
    removeCookie('session');

    fetch(`${Config.serverAddr}/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        session: cookies.session
      })
    })
    .then((response) => response.json())
    .then((data) => {
      handleServerMessage(data);
    })
    .catch((error) => console.error(error));
  }

  function saveNow(id, body) {
    if (noteRef.current === id) {
      setSaveStatus(SAVE_STATUS_SAVING);
    }

    fetch(`${Config.serverAddr}/write`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        session: cookies.session,
        id: id,
        body: body
      })
    })
    .then((response) => response.json())
    .then((data) => {
      handleServerMessage(data, (data) => {
        if (noteRef.current == id) {
          setSaveStatus(SAVE_STATUS_SAVED);

          // Force update
          setBarState(barState + 1);

          setModTime(data.modified);
        }
        window.onbeforeunload = null;
      });
    })
    .catch((error) => console.error(error));
  }


  function handleLoadNote(index) {
    if (index == noteRef.current) {
      return;
    }

    if (dirtyRef.current) {
      // Save this note immediately
      editorRef.current.save();
      saveNow(noteRef.current, editorRef.current.getContent());
      setDirty(0);
    }

    navigate(`/${folder}/${index ? index : ''}`);
    setSaveStatus(SAVE_STATUS_NONE);
  }

  function deepClone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function handleUpdateTitle(id, title) {
    if (id == noteRef.current) {
      setSaveStatus(SAVE_STATUS_SAVING);
    }

    fetch(`${Config.serverAddr}/write`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        session: cookies.session,
        id: noteRef.current,
        title: title
      })
    })
    .then((response) => response.json())
    .then((data) => {
      handleServerMessage(data, () => {
        if (noteRef.current == id) {
          setSaveStatus(SAVE_STATUS_SAVED);
        }

        // Force update
        setBarState(barState + 1);
      });
    })
    .catch((error) => console.error(error));
  }

  function closeMenu() {
    setMenu([]);
  }

  function toggleSettingsMenu(event) {
    if (menu.length > 0) {
      closeMenu();
      return;
    }

    let target = event.target;

    while (target && target.tagName != 'BUTTON') {
      target = target.parentElement;
    }

    const rect = target.getBoundingClientRect();
    setMenuPos({x: rect.x, y: rect.y + rect.height});
    setMenu([
      {text: 'Logout', action: Logout}
    ]);
  }

  const DUAL_MINIMUM = 600;
  var sidebarVisible = (screenWidth >= DUAL_MINIMUM || !noteRef.current);
  var editorVisible = (screenWidth >= DUAL_MINIMUM || noteRef.current);

  return (
    <div className="outer">
      {sidebarVisible && <Sidebar folder={folder} note={note} onLoadNote={handleLoadNote} barState={barState} session={cookies.session} handleServerMessage={handleServerMessage} toggleSettingsMenu={toggleSettingsMenu}></Sidebar>}
      {editorVisible && <div className="main"><NoteEditor note={note} session={cookies.session} saveStatus={saveStatus} onLoadNote={handleLoadNote} handleServerMessage={handleServerMessage} handleUpdateTitle={handleUpdateTitle} dirty={dirty} setDirty={setDirty} modTime={modTime} setModTime={setModTime} editorRef={editorRef}></NoteEditor></div>}
      <Menu items={menu} x={menuPos.x} y={menuPos.y} closeMenu={closeMenu} />
    </div>
  );
}

function Home() {
  const navigate = useNavigate();

  function enter() {
    navigate('/login');
  }

  return (
    <div className="homePage">
      <h1>NoteKC</h1>
      <NoteIcon />
      <h2>Extremely simple yet powerful note taking app.</h2>
      <div>
        <button onClick={enter}>Login</button>
      </div>
    </div>
  );
}

function App() {
  var h = <Home />
  var m = <Main />;

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={h} />
        <Route path='/login' element={m} />
        <Route path=':folder' element={m} />
        <Route path=':folder/:note' element={m} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
